import { FormControl, Validators } from '@angular/forms';
import { TicketProductProperties } from './ticket-product.model';

export class TicketProductForm {
  ticketProductID: FormControl;
  productID: FormControl;
  ticketID: FormControl;
  retailPrice: FormControl;
  discountAmount: FormControl;
  discountReason: FormControl;
  workStatus: FormControl;
  approvalStatus: FormControl;
  taxable: FormControl;
  quantity: FormControl;
  deleted: FormControl;
  subtitle: FormControl;
  optionalTitle: FormControl;
  notes: FormControl;
  createdAt: FormControl;
  createdBy: FormControl;
  modifiedAt: FormControl;
  modifiedBy: FormControl;

  constructor(data?: TicketProductProperties) {
    console.log('ticketProductForm init', data);

    for (const key in data) {
      // if(this[key]) {
      //   this[key].setValue(data[key]);
      // }else {
        this[key] = new FormControl(data[key]);
      // }
    }
    console.log('ticketProductForm setup', this);
  }
}
