import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from './models/device.model';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private endpoint: string = 'device';
  data$: BehaviorSubject<Device[]>;
  data: Device[];

  constructor(
    private api: ApiService
  ) {
    this.data$ = new BehaviorSubject<Device[]>(null);
  }

  get() {
    return this.api.get(this.endpoint)
      .pipe(map((res: Device[]) => {
        console.log('devices retrieved', res.length);
        this.data = res;
        this.data$.next(this.data);
        return res;
      }));
  }

  getById(id: number) {
    return this.api.get(`${this.endpoint}/${id}`)
      .pipe(map((res: Device) => {
        console.log('device retrieved', res);
        return res;
      }));
  }

  getByLocation(locationID: number) {
    return this.api.get(`${this.endpoint}/GetByLocation/${locationID}`)
      .pipe(res => {
        return res;
      });

  }

  saveDevice(device: Device) {
    let url = `${this.endpoint}`;
    if (device.deviceID) {
      url = `${url}/${device.deviceID}`;
      return this.api.put(url, device);
    } else {
      return this.api.post(url, device);
    }
  }
}
